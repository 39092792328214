/* Basic styling */
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  html {
    height: 100vh;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  .caps {
    text-transform: uppercase;
  }
  
  .centered {
    text-align: center;
  }

  .fig-caption {
    padding-left: 5px;
    font-size: .875rem;
  }

  .margin {
    margin-top: 193px;
  }
  .load-error span {visibility: hidden;}