/* =============== Variables =============== */
:root {
  /* dark shades of primary color*/
   --clr-primary-1: hsl(21, 91%, 17%);
   --clr-primary-2: hsl(21, 84%, 25%);
   --clr-primary-3: hsl(21, 81%, 29%);
   --clr-primary-4: hsl(21, 77%, 34%);
   --clr-primary-5: hsl(21, 62%, 45%);
   --clr-primary-6: hsl(21, 57%, 50%);
   --clr-primary-7: hsl(21, 65%, 59%);
   --clr-primary-8: hsl(21, 80%, 74%);
   --clr-primary-9: hsl(21, 94%, 87%);
   --clr-primary-10: hsl(21, 100%, 94%);
  /* darkest grey - used for headings */
   --clr-grey-1: hsl(209, 61%, 16%);
   --clr-grey-2: hsl(211, 39%, 23%);
   --clr-grey-3: hsl(209, 34%, 30%);
   --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
   --clr-grey-5: hsl(210, 22%, 49%);
   --clr-grey-6: hsl(209, 23%, 60%);
   --clr-grey-7: hsl(211, 27%, 70%);
   --clr-grey-8: hsl(210, 31%, 80%);
   --clr-grey-9: hsl(212, 33%, 89%);
   --clr-grey-10: hsl(210, 36%, 96%);
   --clr-white: #fff;
   --clr-red-dark: hsl(360, 67%, 44%);
   --clr-red-light: hsl(360, 71%, 66%);
   --clr-green-dark: hsl(125, 67%, 44%);
   --clr-green-light: hsl(125, 71%, 66%);
   --clr-black: #222;
   --transition: all 0.3s linear;
   --spacing: 0.1rem;
   --radius: 0.25rem;
   --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
   --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
   --max-width: 1170px;
   --fixed-width: 620px;
}
/* =============== Global Styles =============== */
/* section */
.facts {
   width: 95%;
   margin: 0 auto 45px auto;
   box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.24), 1px 2px 4px 0px rgba(0,0,0,0.24), 2px 4px 8px 0px rgba(0,0,0,0.24), 2px 4px 16px 0px rgba(0,0,0,0.24);
   border-radius: 10px;
}
/* =============== Slider =============== */
.facts-center {
   margin: 0 auto;
   width: 80vw;
  /* have to have a height */
   height: 302px;
   max-width: 800px;
   text-align: left;
   position: relative;
   display: flex;
   overflow: hidden;
}
.facts-center h3 {
   font-size: 1.25rem;
   letter-spacing: .037rem;
}
.prev, .next {
   position: absolute;
   top: 265px;
   transform: translateY(-50%);
   background: #011e56;
   color: var(--clr-white);
   width: 1.25rem;
   height: 1.25rem;
   display: grid;
   place-items: center;
   border-color: transparent;
   font-size: 1rem;
   border-radius: var(--radius);
   cursor: pointer;
   transition: var(--transition);
}
.prev:hover, .next:hover {
   background: #1493f3;
}
.prev {
   left: 0;
}
.next {
   right: 0;
}
.facts-center article {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0;
   transition: var(--transition);
   display: flex;
   justify-content: center;
   align-items: center;
   line-height: 1.4;
}
article.activeSlide {
   opacity: 1;
   transform: translateX(0);
}
article.lastSlide {
   transform: translateX(-100%);
}
article.nextSlide {
   transform: translateX(100%);
}
@media screen and (max-width: 250px) {
   .prev, .next {
       top: 283px;
  }
}
/* Tablet menu */
@media all and (min-width: 700px) {
   .facts-center h3 {
       font-size: 2rem;
  }
   .facts-center article {
       line-height: 2.9rem;
  }
}
@media (min-width: 800px) {
   .prev, .next {
       width: 2rem;
       height: 2rem;
       font-size: 1.5rem;
  }
}
@media screen and (min-width: 960px) {
   .facts {
       margin-bottom: 65px;
  }
}
@media screen and (min-width: 992px) {
   .facts {
       width: 80%;
  }
}
@media screen and (min-width: 1500px) {
   .facts {
       margin-bottom: 75px;
  }
}
@media screen and (min-width: 1687px) {
   .facts {
       width: 50%;
  }
}
