/* mobile menu */
.no-right-click {
  pointer-events: none;
}
.home-content {
  margin-top: 241px;
  margin-bottom: auto;
}
.home-content .mission {
  line-height: 1.6;
  letter-spacing: .04rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 242px;
  border-top: 1px solid transparent;
  background-color: #fff;
  font-weight: 500;
  margin-top: -102px;
}
.mission h1 {
  font-size: 1.5rem;
  margin-bottom: 13px;
  letter-spacing: .02em;
  font-weight: 900;
}
.mission p {
  width: 315px;
  text-align: left;
}
figure {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
.feature-photo-container figure {
  padding-top: calc(858 / 1526 * 100%);
}
.card-container figure {
  padding-top: calc(277 / 492 * 100%);
}
.hero-image figure {
  border-radius: 0;
  padding-top: calc(769 / 2543 * 100%);
}
.card-container {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 45px auto;
}
.card-container article:hover img, .card-container article a:focus img, .feature-anchor:hover img, .feature-anchor:focus img {
  border-radius: 0;
  transform: scale(1.2);
}
.card-container article:hover, .feature-anchor:hover .feature-wrapper {
  box-shadow: rgba(20, 147, 243) 0px 3px 8px;
}
.blog-article-content {
  padding: 10px;
  position: relative;
}
.card-img img, .feature-anchor img, .hero-image img {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
  transition: 1s ease;
  position: absolute;
  top: 0;
  left: 0;
}
.featured-content-container h3, .card-container h3 {
  font-size: 1.5rem;
}
.card-container article {
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 45px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: .3s ease;
}
.card-container article:nth-child(12) {
  margin-bottom: 0;
}
.line-divider {
  position: relative;
  margin: 45px 0 45px 
}
.line-divider.wide:before {
  content: "";
  height: 1px;
  width: 95%;
  left: 2.5%;
  display: block;
  position: absolute;
  top: 52%;
  margin-top: -1px;
  background-color: #a1a1a1;
}
.line-divider.feature.wide:before {
  width: 100%;
  left: 0;
}
.diamond-text-box {
  position: relative;
  display: inline-block;
}
.diamond-text-box .left:before, .diamond-text-box .left::after {
  content: "";
  top: 52%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #888;
  border-width: 17px;
  margin-top: -17px;
  right: 100%;
}
.diamond-text-box .left::after {
  border-right-color: #FFF;
  border-width: 16px;
  margin-top: -16px;
}
.diamond-text-box .right:before, .diamond-text-box .right::after {
  content: "";
  top: 52%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-left-color: #888;
  border-width: 17px;
  margin-top: -17px;
  left: 100%;
}
.diamond-text-box .right::after {
  border-left-color: #fff;
  border-width: 16px;
  margin-top: -16px;
}
.diamond-text-box .content {
  display: inline-block;
  border: 1px solid #b0b0b0;
  border-left: none;
  border-right: none;
  background: #fff;
  color: #6c6c6c;
  height: 34px;
  line-height: 34px;
  font-weight: 400;
  margin-top: 1px;
  font-size: 1.3125rem;
}
.featured-content-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 95%;
}
.feature-photo-container {
  flex: 1 0 70%;
}
.feature-anchor .feature-wrapper {
  transition: 1s ease;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 10px 10px;
}
.featured-article {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 25px;
}
/* small resolution media queries */
@media all and (max-width: 336px) {
  .home-content .mission {
      height: 167px;
      line-height: 1.138rem;
 }
  .mission p {
      width: 250px;
      font-size: .875rem;
      padding: 0 12px;
 }
}
@media all and (max-width: 230px) {
  .home-content {
      margin-top: 239px;
 }
  .mission h1 {
      font-size: 1.4rem;
      white-space: nowrap;
 }
}
@media all and (max-width: 195px) {
  .home-content {
      margin-top: 197px;
 }
}
/* Tablet menu */
@media all and (min-width: 700px) {
  .mission p {
      width: 488px;
      font-size: 1.125rem;
 }
}
/* Desktop menu */
@media all and (min-width: 960px) {
  .featured-content-container {
      width: 60%;
      margin: auto;
 }
  .card-container {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: 80%;
 }
  .card-container article {
      margin-right: 25px;
      margin-bottom: 0;
      width: calc((100% - 75px) / 3);
      height: 100%;
 }
 .card-container article:nth-child(-n+9) {
  margin-bottom: 25px;
 }
 .card-container article:nth-child(3), .card-container article:nth-child(6), .card-container article:nth-child(9), .card-container article:nth-child(12) {
  margin-right: 0;
 }
  .mission h1 {
      font-size: 2rem;
 }
  .line-divider {
      margin: 65px 0 65px;
 }
  .line-divider.wide:before {
      width: 80%;
      left: 10%;
 }
  .home-content {
      margin-top: 193px;
 }
}
@media only screen and (min-width: 960px) and (max-width: 1050px) {
  .card-container article {
      height: 257px;
 }
}
@media only screen and (min-width: 1050px) and (max-width: 1209px) {
  .card-container article {
      height: 245px;
 }
}
@media only screen and (min-width: 1209px) and (max-width: 1460px) {
  .card-container article {
      height: 276px;
 }
}
@media only screen and (min-width: 1600px) and (max-width: 1755px) {
  .card-container article {
      height: 264px;
 }
}
@media only screen and (min-width: 1755px) and (max-width: 1923px) {
  .card-container article {
      height: 285px;
 }
}
@media only screen and (min-width: 1924px) and (max-width: 1984px) {
  .card-container article {
      height: 288px;
 }
}
@media only screen and (min-width: 1984px) and (max-width: 2063px) {
  .card-container article {
      height: 292px;
 }
}
@media only screen and (min-width: 2063px) and (max-width: 2374px) {
  .card-container article {
      height: 328px;
 }
}
@media only screen and (min-width: 2375px) and (max-width: 2509px) {
  .card-container article:nth-child(n+7):nth-child(-n+12) {
      height: 336px;
 }
}
@media only screen and (min-width: 2510px) and (max-width: 5500px) {
  .card-container article:nth-child(n+7):nth-child(-n+12) {
      height: 352px;
 }
}
@media all and (min-width: 1500px) {
  .line-divider.feature.wide:before {
      width: 100%;
      left: 0%;
 }
  .line-divider.wide:before {
      width: 60%;
      left: 20%;
 }
  .line-divider {
      margin: 75px 0 75px;
 }
}
@media all and (min-width: 1600px) {
  .card-container {
      width: 60%;
 }
}
