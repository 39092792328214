.recipe-page {
    margin-top: 142px;
}

.recipe-page .blog-details h2, .recipe-page .blog-details h3 {
    margin-bottom: 15px;
}

.recipe-page .last-p-of-section {
  margin-bottom: 0;
}

.recipe-header {
    text-align: center;
    text-transform: uppercase;
}

.recipe-page .blog-details .line-divider {
    margin-top: 45px;
}

.recipe-page h1 {
    font-size: 1.3125rem;
}

/* Tablet menu */
@media all and (min-width: 960px) {
    .recipe-page {
        margin-top: 92px;
   }
   
}

