.search-wrap {
  width: 95%;
  position: absolute;
  right: 0;
  z-index: 2;
  left: 0;
  top: 97px;
  bottom: 0;
  margin: auto;
  transition: 0.5s;
}

.header.header.is-sticky .search-wrap {
  top: 76px;
}

.header.header.is-sticky .search-term {
  height: 24px;
}

.header.header.is-sticky .search-icon {
  top: 2px;
}

.search button {
  border: none;
  font-size: 1.1rem;
  color: #a1a1a1;
  background: none;
}

.search {
  width: 100%;
  position: relative;
  display:flex;
}

.search-term {
  width: 100%;
  outline: 1px solid #a1a1a1;
  padding: 6px;
  border-radius: 10px;
  border: none;
  font-size: 1rem;
}

.search-term:focus {
  color: #000;
  outline: 1px solid #1493f3;
  border: none;
}
.search-icon {
  background-color: white;
  position: absolute;
  right: 5px;
  top: 5px;
}

.search-icon svg {
  font-size: 1.1rem;
}

.dataResult {
  margin-top: 5px;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  display: flex;
  align-items: center;
  color: black;
  padding: 5px 0;
}

.dataItem p {
  margin-left: 10px;
}

.search-wrap a {
  text-decoration: none;
}

.search-wrap a:hover {
  background-color: lightgrey;
}

.search #clearBtn {
  cursor: pointer;
}

/* desktop menu */

@media all and (min-width: 960px) {
  .search-wrap {
      width: 344px;
      left: auto;
      right: 13px;
      top: 53px;
 }

  .search-term {
      height: 24px;
 }

  .header.header.is-sticky .search-wrap {
      top: 38px;
      width: 314px;
 }

  .search-icon {
      top: 2px;
 }
}

@media all and (max-width: 285px) {
  .search-wrap {
      top: 81px;
 }

  .header.header.is-sticky .search-wrap {
      top: 78px;
 }
}

@media all and (max-width: 195px) {
  .header.header.is-sticky .search-wrap {
      top: 59px;
 }
  .search-wrap {
      top: 60px;
 }
}
