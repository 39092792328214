.blog-details {
    width: 90%;
    margin: 190px auto 41px auto;
    line-height: 1.6;
    font-size: 1.125rem;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    letter-spacing: 0.01em;
}

.blog-details .embassy-address {
    margin-bottom: 6px;
}

.blog-details section .extra-margin {
    margin-bottom: 20px;
}

/* checkbox styles */
.checkbox-input {
    position: relative;
    left: -99999999px;
}

.input-label-parent {
    display: flex;
    margin-bottom: 9px;
    position: relative;
    z-index: 1;
}

.six-month-list {
    padding: 35px 25px 25px 25px;
    border-color: black;
    border-width: 4px;
    position: relative;
    margin-bottom: 25px;
}

.checkbox-label {
    display: inline-block;
    width: 54px;
    height: 27px;
    background-color: #000;
    border-radius: 15px;
    position: relative;
    margin-bottom: 15px;
    left: -20px;
}

.checkbox-label::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: 2px;
    transition: left 0.3s;
}

/* Style the checked state */
.checkbox-input:checked + .checkbox-label::after {
    left: 32px;
}

.checkbox-input:checked + .checkbox-label {
    background-color: #1493f3;
}

.blog-details .six-month-list h3 {
    margin-bottom: 0;
    padding: 0 10px;
}

.blog-details .six-month-list p {
    margin: 0 0 0 59px;
    position: absolute;
    line-height: 1.3;
}

.checkbox-input:focus + .checkbox-label::before {
    border: 2px solid #000;
    content: "";
    height: 37px;
    left: -5px;
    position: absolute;
    top: -6px;
    width: 63px;
}

.six-month-list .organize-any {
    margin-bottom: 32px;
}

.six-month-list .get-hep {
    margin-bottom: 114px;
}

.six-month-list .learn-all {
    margin-bottom: 60px;
}

.six-month-list .plan-data {
    margin-bottom: 60px;
}

.six-month-list .talk-banks {
    margin-bottom: 123px;
}

.six-month-list .check-banks {
    margin-bottom: 148px;
}

.six-month-list .travel-site {
    margin-bottom: 44px;
}

.six-hundred {
    font-weight: 600;
}

.link {
    color: #4169e1;
    font-weight: 600;
    position: relative;
    z-index: 1;
}

.no-marker {
    list-style-type: none;
}

.link:hover {
    text-decoration: underline;
}
.safety,
.transportation,
.el-cangrejo,
.panama-canal,
.spanish,
.food,
.culture,
.whats-app,
.fly-cheap,
.english,
.recipe-page,
.packing,
.must-know {
    flex: 1;
    position: relative;
}

.button-parent {
    position: absolute;
    bottom: -79px;
    display: flex;
    width: 100%;
}

.button-parent button:first-child {
    margin-right: auto;
}

.button-parent button {
    padding: 10px;
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid black;
    cursor: pointer;
    letter-spacing: 0.03em;
    transition: 0.3s;
}

.share-parent {
    display: flex;
    position: relative;
    top: -3px;
}

.share-parent button {
    margin-right: 4px;
}

.share-parent button:last-of-type {
    margin-right: 0;
}

.button-parent button:hover,
.button-parent button:focus {
    background-color: #1493f3;
    color: #fff;
    border: 2px solid #1493f3;
}

.date span.content {
    font-size: 0.75rem;
}

.blog-details .post-title {
    letter-spacing: 0.05em;
    font-size: 1.875rem;
    line-height: 1.11;
}

.blog-details figure {
    margin-bottom: 25px;
}

.blog-details .last-figure {
    margin-bottom: 0;
}

.blog-details figcaption,
.metro-caption,
.video-caption {
    font-size: 1rem;
    font-weight: normal;
}

.blog-details .casco-petty-crime {
    margin-bottom: 0;
}

.blog-details img {
    max-width: 100%;
    height: auto;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
}

.blog-details p {
    margin-bottom: 20px;
}

.blog-details p.second-para {
    margin-bottom: 0px;
}

.blog-details .line-divider {
    margin-top: 0;
    margin-bottom: 45px;
}

.recipe-page .blog-details .recipe-header h2 {
    margin-bottom: 45px;
}

.recipe-page .blog-details .line-divider.first {
    margin-top:0;
}

.blog-details + footer .line-divider,
.recipe-page + footer .line-divider {
    margin-top: 84px;
}

p.intro,
div.intro {
    padding: 25px 0;
    border-top: 1px solid #a1a1a1;
    border-bottom: 1px solid #a1a1a1;
    margin: 45px auto 22px auto;
    font-weight: 600;
    line-height: 1.4;
    font-size: 1.5rem;
    width: 100%;
    letter-spacing: 0.025em;
}

p.intro.no-margin-top {
    margin-top: 0;
}

p.this-article {
    margin-bottom: 45px;
}

.diamond-text-box.date {
    margin-bottom: 0;
}

.blog-details h2 {
    display: inline-block;
}

.blog-details h2,
.blog-details h3,
.blog-details h4 {
    font-size: 1.438rem;
    line-height: 1.1;
}

.blog-details h3 {
    font-size: 1.313rem;
}

.blog-details h4 {
    font-size: 1.188rem;
}

.blog-details section h2,
.blog-details section h3,
.blog-details h4 {
    margin-bottom: 15px;
}

.blog-details ul li {
    margin-left: 48px;
    margin-bottom: 20px;
    list-style: none;
    position: relative;
    transition: 0.12s;
    z-index: 1;
}

.blog-details ul li::before {
    position: absolute;
    content: "\2022";
    font-family: Arial;
    color: #000;
    top: -1px;
    left: -26px;
    text-align: center;
    font-size: 2.5em;
    line-height: 0.75;
    transition: 0.5s;
}

.el-cangrejo ul li {
    font-weight: bold;
    list-style-type: none;
}

.el-cangrejo ul span,
.el-cangrejo ul:nth-of-type(2) li {
    font-weight: normal;
}

.youtube-video {
    aspect-ratio: 16 / 9;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

/* saftey page images */
.cops-at-fruitstand {
    padding-top: calc(1180 / 1170 * 100%);
}
.bank-police,
.food .delicias,
.food .jardin,
.food .lsalad,
.food .margot-front,
.culture .coronado-beach,
.whats-app .cell-store, .must-know .check-point, .must-know .traffic {
    padding-top: calc(878 / 1170 * 100%);
}
.casco-tourist,
.casco-police,
.colon-plate,
.casco-petty-crime,
.market-police,
.near-casco,
.culture figure, .must-know .taxi {
    padding-top: calc(658 / 1170 * 100%);
}
.video-container {
    padding-top: calc(226 / 402 * 100%);
    position: relative;
}
/* transportaion page images */
.rapi-card {
    padding-top: calc(739 / 1170 * 100%);
    position: relative;
    margin-bottom: 20px;
}
.chiva {
    padding-top: calc(878 / 1170 * 100%);
}
.metro-map {
    padding-top: calc(497 / 800 * 100%);
}
.city-center {
    padding-top: calc(1147 / 772 * 100%);
}
.free-shuttle {
    padding-top: calc(584 / 1170 * 100%);
}
.metro-bus {
    padding-top: calc(407 / 966 * 100%);
}
.diablos,
.chicken-bus,
.yellow-cabs {
    padding-top: calc(658 / 1170 * 100%);
}
.albrook-terminal {
    padding-top: calc(791 / 1170 * 100%);
}
.comfy-bus,
.coronado-stop,
.taxi-truck,
.mini-van,
.inside-van,
.hwy {
    padding-top: calc(877 / 1169 * 100%);
}
.coaster-bus {
    padding-top: calc(882 / 1170 * 100%);
}
.coronado-map {
    padding-top: calc(663 / 820 * 100%);
}
.gates {
    padding-top: calc(377 / 1170 * 100%);
}
.alex-bus,
.food .coronado-breakfast {
    padding-top: calc(1560 / 1170 * 100%);
}
/* el cangrejo/spanish page images */
.el-cangrejo figure,
.spanish figure,
.food figure,
.english .panama-flag,
.packing .skyline-pa {
    padding-top: calc(658 / 1171 * 100%);
}
.el-cangrejo .crab {
    padding-top: calc(687 / 715 * 100%);
}
.el-cangrejo .lee,
.el-cangrejo .manlos,
.el-cangrejo .sen {
    padding-top: calc(632 / 1124 * 100%);
}
/* spanish page images */
.spanish .alto {
    padding-top: calc(1560 / 1171 * 100%);
}
.spanish .school {
    padding-top: calc(878 / 1171 * 100%);
}
/* food page images */
.food .dessert {
    padding-top: calc(856 / 1168 * 100%);
}
.food .burger {
    padding-top: calc(815 / 1170 * 100%);
}
.food .ceviche {
    padding-top: calc(1155 / 1170 * 100%);
}
.food .coco {
    padding-top: calc(1044 / 1170 * 100%);
}
.food .pina {
    padding-top: calc(1816 / 1170 * 100%);
}
.food .portobelo {
    padding-top: calc(447 / 1170 * 100%);
}
.food .highway-fonda {
    padding-top: calc(726 / 1170 * 100%);
}
.food .take-out {
    padding-top: calc(993 / 1170 * 100%);
}
.food .lobster {
    padding-top: calc(1449 / 1170 * 100%);
}
.food .colon-fonda {
    padding-top: calc(724 / 1170 * 100%);
}
/* culture page images */
.culture .tip {
    padding-top: calc(988 / 1170 * 100%);
}
.culture .hat {
    padding-top: calc(1470 / 1170 * 100%);
}
.culture .exercise {
    padding-top: calc(528 / 1170 * 100%);
}
/* canal page images */
.panama-canal figure {
    padding-top: calc(2752 / 4896 * 100%);
}
.panama-canal .map {
    padding-top: calc(703 / 1108 * 100%);
}
.panama-canal .pov {
    padding-top: calc(1920 / 7376 * 100%);
}

/* Sim card images height/width */
.sim-card-front {
    padding-top: calc(714 / 1077 * 100%);
}
.sim-card-back {
    padding-top: calc(570 / 873 * 100%);
}
/* flight card images height/width */
.airplane-window, .must-know .unpaved {
    padding-top: calc(1560 / 1170 * 100%);
}
/* recipe page images height/width */
.panama-papayas {
    padding-top: calc(1170 / 1170 * 100%);

}
.panama-plantains {
    padding-top: calc(666 / 1170 * 100%);
}
.egg-dishes {
    padding-top: calc(1075 / 1170 * 100%);
}
/* must know images height/width */
.must-know .ferry {
    padding-top: calc(460 / 1170 * 100%);
}

.must-know .canvas {
    padding-top: calc(928 / 1170 * 100%);
}

.must-know .vehicle {
    padding-top: calc(1013 / 1170 * 100%);
}
.must-know .panama-woman {
    padding-top: calc(710 / 1170 * 100%);
}

.must-know .panama-money {
    padding-top: calc(230 / 1170 * 100%);
}





@media all and (min-width: 527px) {
    .six-month-list .get-hep {
        margin-bottom: 38px;
    }
    .six-month-list .learn-all {
        margin-bottom: 21px;
    }
    .six-month-list .plan-data {
        margin-bottom: 40px;
    }
    .six-month-list .talk-banks {
        margin-bottom: 70px;
    }
    .six-month-list .check-banks {
        margin-bottom: 91px;
    }
    .six-month-list .travel-site {
        margin-bottom: 28px;
    }
    .six-month-list .pack-up, .six-month-list .verify-this {
        margin-bottom: -10px;
    }
    .six-month-list .check-pass, .six-month-list .toothbrush {
        margin-bottom: 23px;
    }
   
    .six-month-list .change-of {
        margin-bottom: 17px;
    }
    .six-month-list .jacket {
        margin-bottom: 52px;
    }
    .six-month-list .devices {
        margin-bottom: 29px;
    }
    .six-month-list .water-bottle {
        margin-bottom: 13px;
    }
    .six-month-list .electro {
        margin-bottom: 30px;
    }
    .six-month-list .book {
        margin-bottom: 14px;
    }
    .six-month-list .quarters {
        margin-bottom: 25px;
    }

    .six-month-list .sunglasses {
        margin-bottom: 11px;
    }
    
    .six-month-list .wear-sneakers {
        margin-bottom: -3px;
    }
}

@media all and (min-width: 547px) {
    .six-month-list .talk-banks {
        margin-bottom: 68px;
    }
    .six-month-list .check-banks {
        margin-bottom: 92px;
    }
}

@media all and (min-width: 600px) {
    .six-month-list .organize-any {
        margin-bottom: 16px;
    }
    .six-month-list .plan-data {
        margin-bottom: 22px;
    }
    .six-month-list .talk-banks {
        margin-bottom: 65px;
    }
    .six-month-list .check-banks {
        margin-bottom: 67px;
    }
}

/* Tablet menu */
@media all and (min-width: 700px) {
    .blog-details h2 {
        font-size: 2rem;
    }
    .blog-details h3 {
        font-size: 1.75rem;
    }
    .six-month-list .get-hep {
        margin-bottom: 41px;
    }
    .six-month-list .talk-banks {
        margin-bottom: 48px;
    }
    .six-month-list .check-banks {
        margin-bottom: 51px;
    }
}

@media all and (min-width: 879px) {
    .six-month-list .get-hep {
        margin-bottom: 27px;
    }
    .six-month-list .learn-all {
        margin-bottom: 14px;
    }
    .six-month-list .talk-banks {
        margin-bottom: 26px;
    }
    .six-month-list .travel-site {
        margin-bottom: 17px;
    }
}

/* Desktop menu */
@media all and (min-width: 960px) {
    .share-parent {
        flex-direction: row;
        position: fixed;
        left: 13px;
        top: 55%;
        flex-direction: column;
    }
    .share-parent button {
        margin-right: 0;
    }
    .blog-details {
        width: 46%;
        margin-bottom: -22px;
    }
    .blog-details .post-title {
        font-size: 2.813rem;
    }
    p.intro,
    div.intro {
        width: 80%;
        margin: 45px auto;
    }
    .button-parent {
        position: fixed;
        display: flex;
        width: 99.5%;
        top: 50%;
        left: 0.5%;
        bottom: unset;
    }
    .button-parent button:last-child {
        margin-right: 6px;
    }
    p.conclusion {
        width: auto;
    }
    .six-month-list .plan-data {
        margin-bottom: 43px;
    }
    .six-month-list .talk-banks {
        margin-bottom: 88px;
    }

    .six-month-list .check-banks {
        margin-bottom: 91px;
    }
    .six-month-list .organize-any {
        margin-bottom: 22px;
    }
    .six-month-list .start-getting {
        margin-bottom: 30px;
    }
    .six-month-list .get-hep {
        margin-bottom: 67px;
    }
    .six-month-list .learn-all {
        margin-bottom: 23px;
    }
    .six-month-list .travel-site {
        margin-bottom: 24px;
    }
    .six-month-list .verify-this {
        margin-bottom: 9px;
    }
    .six-month-list .pack-up {
        margin-bottom: -4px;
    }
    .six-month-list .jacket {
        margin-bottom: 65px;
    }

    .six-month-list .electro {
        margin-bottom: 47px;
    }
}

@media all and (min-width: 988px) {
    .six-month-list .talk-banks {
        margin-bottom: 71px;
    }
}


@media all and (min-width: 1500px) {
    .blog-details .line-divider.wide:before {
        width: 50%;
        left: 25%;
    }
}

@media all and (max-width: 265px) {
    .blog-details {
        margin-top: 174px;
    }
}

/* checkbox form media queries */

@media all and (max-width: 306px) {
    .input-label-parent {
        margin-bottom: 29px;
    }
    .six-month-list .organize-any {
        margin-bottom: 83px;
    }

    .six-month-list .get-hep {
        margin-bottom: 224px;
    }

    .six-month-list .start-getting {
        margin-bottom: 57px;
    }

    .six-month-list .learn-all {
        margin-bottom: 104px;
    }

    .six-month-list .plan-data {
        margin-bottom: 156px;
    }
    .six-month-list .talk-banks {
        margin-bottom: 227px;
    }
    .six-month-list .check-banks {
        margin-bottom: 318px;
    }
    .six-month-list .travel-site {
        margin-bottom: 107px;
    }
    .six-month-list .verify-this {
        margin-bottom: 49px;
    }
    .six-month-list .one-to-two-weeks {
        margin-bottom: 21px;
    }

    .six-month-list .make-two {
        margin-bottom: 69px;
    }

    .six-month-list .mail-email {
        margin-bottom: 45px;
    }

    .six-month-list .pack-up {
        margin-bottom: -18px;
    }
    .six-month-list .check-pass, .six-month-list .toothbrush {
        margin-bottom: 69px;
    }
   
    .six-month-list .change-of {
        margin-bottom: 48px;
    }
    .six-month-list .jacket {
        margin-bottom: 218px;
    }
    .six-month-list .devices {
        margin-bottom: 124px;
    }
    .six-month-list .water-bottle {
        margin-bottom: 47px;
    }
    .six-month-list .electro {
        margin-bottom: 143px;
    }
    .six-month-list .book {
        margin-bottom: 48px;
    }
    .six-month-list .quarters {
        margin-bottom: 95px;
    }
    .six-month-list .wear-sneakers {
        margin-bottom: 33px;
    }
}

@media all and (max-width: 272px) {
    .six-month-list .talk-banks {
        margin-bottom: 264px;
    }
    .input-label-parent {
        margin-bottom: 48px;
    }
    .six-month-list .travel-site {
        margin-bottom: 113px;
    }
    .six-month-list .get-hep {
        margin-bottom: 241px;
    }
    .six-month-list .learn-all {
        margin-bottom: 118px;
    }
    .six-month-list .check-banks {
        margin-bottom: 335px;
    }
    .six-month-list .organize-any {
        margin-bottom: 94px;
    }
    .six-month-list .start-getting {
        margin-bottom: 77px;
    }
    .six-month-list .plan-data {
        margin-bottom: 171px;
    }
    .six-month-list .one-to-two-weeks {
        margin-bottom: 21px;
    }

    .six-month-list .make-two {
        margin-bottom: 90px;
    }

    .six-month-list .mail-email {
        margin-bottom: 45px;
    }

    .six-month-list .pack-up {
        margin-bottom: -18px;
    }

    .six-month-list .check-pass {
        margin-bottom: 113px;
    }
    .six-month-list .toothbrush {
        margin-bottom: 70px;
    }
    .six-month-list .change-of {
        margin-bottom: 48px;
    }
    .six-month-list .jacket {
        margin-bottom: 210px;
    }
    .six-month-list .devices {
        margin-bottom: 138px;
    }
    .six-month-list .water-bottle {
        margin-bottom: 44px;
    }
    .six-month-list .electro {
        margin-bottom: 143px;
    }
    .six-month-list .book {
        margin-bottom: 48px;
    }
    .six-month-list .quarters {
        margin-bottom: 95px;
    }
    .six-month-list .wear-sneakers {
        margin-bottom: 33px;
    }
}

@media all and (max-width: 259px) {
    .six-month-list .talk-banks {
        margin-bottom: 368px;
    }
    .input-label-parent {
        margin-bottom: 82px;
    }
    .six-month-list .travel-site {
        margin-bottom: 124px;
    }
    .six-month-list .get-hep {
        margin-bottom: 278px;
    }
    .six-month-list .learn-all {
        margin-bottom: 128px;
    }
    .six-month-list .check-banks {
        margin-bottom: 429px;
    }
    .six-month-list .organize-any {
        margin-bottom: 135px;
    }
    .six-month-list .start-getting {
        margin-bottom: 84px;
    }
    .six-month-list .plan-data {
        margin-bottom: 242px;
    }
    .six-month-list .verify-this {
        margin-bottom: 91px;
    }

    .six-month-list .one-to-two-weeks {
        margin-bottom: 26px;
    }

    .six-month-list .make-two {
        margin-bottom: 113px;
    }

    .six-month-list .mail-email {
        margin-bottom: 93px;
    }

    .six-month-list .pack-up {
        margin-bottom: 14px;
    }

    .six-month-list .check-pass {
        margin-bottom: 121px;
    }
    .six-month-list .toothbrush {
        margin-bottom: 106px;
    }
    .six-month-list .change-of {
        margin-bottom: 113px;
    }
    .six-month-list .jacket {
        margin-bottom: 329px;
    }
    .six-month-list .devices {
        margin-bottom: 172px;
    }
    .six-month-list .water-bottle {
        margin-bottom: 114px;
    }
    .six-month-list .electro {
        margin-bottom: 211px;
    }
    .six-month-list .book {
        margin-bottom: 114px;
    }
    .six-month-list .quarters {
        margin-bottom: 163px;
    }
    .six-month-list .sunglasses {
        margin-bottom: 48px;
    }
    .six-month-list .wear-sneakers {
        margin-bottom: 86px;
    }

}

@media all and (max-width: 206px) {
    .six-month-list .plan-data {
            margin-bottom: 255px;
    }
    .six-month-list .talk-banks {
        margin-bottom: 559px;
}
}

@media only screen and (min-width: 208px) and (max-width: 231px) {
    .six-month-list .talk-banks {
        margin-bottom: 400px;
    }
    .six-month-list .plan-data {
        margin-bottom: 255px;
    }
}

@media only screen and (min-width: 307px) and (max-width: 337px) {
    .six-month-list .passport {
        margin-bottom: 27px;
    }

    .six-month-list .book-your {
        margin-bottom: 27px;
    }
    .six-month-list .organize-any {
        margin-bottom: 76px;
    }

    .six-month-list .get-hep {
        margin-bottom: 180px;
    }

    .six-month-list .start-getting {
        margin-bottom: 67px;
    }

    .six-month-list .brush-up {
        margin-bottom: 27px;
    }

    .six-month-list .learn-all {
        margin-bottom: 95px;
    }

    .six-month-list .plan-data {
        margin-bottom: 121px;
    }
    .six-month-list .talk-banks {
        margin-bottom: 194px;
    }
    .six-month-list .check-banks {
        margin-bottom: 232px;
    }
    .six-month-list .travel-site {
        margin-bottom: 97px;
    }
    .six-month-list .verify-this {
        margin-bottom: 28px;
    }

    .six-month-list .one-to-two-weeks {
        margin-bottom: 21px;
    }

    .six-month-list .make-two {
        margin-bottom: 69px;
    }

    .six-month-list .mail-email {
        margin-bottom: 45px;
    }

    .six-month-list .pack-up {
        margin-bottom: -18px;
    }
    .six-month-list .check-pass, .six-month-list .toothbrush {
        margin-bottom: 53px;
    }
   
    .six-month-list .change-of {
        margin-bottom: 48px;
    }
    .six-month-list .jacket {
        margin-bottom: 148px;
    }
    .six-month-list .devices {
        margin-bottom: 72px;
    }
    .six-month-list .water-bottle {
        margin-bottom: 47px;
    }
    .six-month-list .electro {
        margin-bottom: 95px;
    }
    .six-month-list .book {
        margin-bottom: 33px;
    }
    .six-month-list .quarters {
        margin-bottom: 95px;
    }

    .six-month-list .sunglasses {
        margin-bottom: 29px;
    }
    
    .six-month-list .wear-sneakers {
        margin-bottom: 15px;
    }
}

@media only screen and (min-width: 338px) and (max-width: 526px) {
    .six-month-list .passport {
        margin-bottom: 27px;
    }

    .six-month-list .book-your {
        margin-bottom: 27px;
    }
    .six-month-list .organize-any {
        margin-bottom: 76px;
    }

    .six-month-list .get-hep {
        margin-bottom: 118px;
    }

    .six-month-list .start-getting {
        margin-bottom: 67px;
    }

    .six-month-list .brush-up {
        margin-bottom: 27px;
    }

    .six-month-list .learn-all {
        margin-bottom: 72px;
    }

    .six-month-list .plan-data {
        margin-bottom: 99px;
    }
    .six-month-list .talk-banks {
        margin-bottom: 166px;
    }
    .six-month-list .check-banks {
        margin-bottom: 187px;
    }
    .six-month-list .travel-site {
        margin-bottom: 94px;
    }
    .six-month-list .verify-this {
        margin-bottom: 5px;
    }

    .six-month-list .one-to-two-weeks {
        margin-bottom: 15px;
    }

    .six-month-list .make-two {
        margin-bottom: 52px;
    }

    .six-month-list .mail-email {
        margin-bottom: 30px;
    }

    .six-month-list .pack-up {
        margin-bottom: -16px;
    }
    .six-month-list .check-pass, .six-month-list .toothbrush {
        margin-bottom: 53px;
    }
   
    .six-month-list .change-of {
        margin-bottom: 40px;
    }
    .six-month-list .jacket {
        margin-bottom: 148px;
    }
    .six-month-list .devices {
        margin-bottom: 72px;
    }
    .six-month-list .water-bottle {
        margin-bottom: 31px;
    }
    .six-month-list .electro {
        margin-bottom: 68px;
    }
    .six-month-list .book {
        margin-bottom: 33px;
    }
    .six-month-list .quarters {
        margin-bottom: 69px;
    }

    .six-month-list .sunglasses {
        margin-bottom: 29px;
    }
    
    .six-month-list .wear-sneakers {
        margin-bottom: 15px;
    }
}

@media only screen and (min-width: 401px) and (max-width: 526px) {
    .six-month-list .book-your {
        margin-bottom: 16px;
    }
    .six-month-list .organize-any {
        margin-bottom: 50px;
    }

    .six-month-list .get-hep {
        margin-bottom: 101px;
    }

    .six-month-list .start-getting {
        margin-bottom: 32px;
    }

    .six-month-list .brush-up {
        margin-bottom: 27px;
    }

    .six-month-list .learn-all {
        margin-bottom: 57px;
    }

    .six-month-list .plan-data {
        margin-bottom: 82px;
    }
    .six-month-list .talk-banks {
        margin-bottom: 127px;
    }
    .six-month-list .check-banks {
        margin-bottom: 149px;
    }
    .six-month-list .travel-site {
        margin-bottom: 58px;
    }
    .six-month-list .verify-this {
        margin-bottom: 18px;
    }
    .six-month-list .check-pass, .six-month-list .toothbrush {
        margin-bottom: 39px;
    }
   
    .six-month-list .change-of {
        margin-bottom: 48px;
    }
    .six-month-list .jacket {
        margin-bottom: 108px;
    }
    .six-month-list .devices {
        margin-bottom: 55px;
    }
    .six-month-list .water-bottle {
        margin-bottom: 29px;
    }
    .six-month-list .electro {
        margin-bottom: 54px;
    }
    .six-month-list .book {
        margin-bottom: 33px;
    }
    .six-month-list .quarters {
        margin-bottom: 56px;
    }

    .six-month-list .sunglasses {
        margin-bottom: 29px;
    }
    
    .six-month-list .wear-sneakers {
        margin-bottom: 15px;
    }
}

@media all and (min-width: 1132px) {
    .six-month-list .plan-data {
        margin-bottom: 25px;
    }
    .six-month-list .check-banks {
        margin-bottom: 67px;
    }
    .six-month-list .jacket {
        margin-bottom: 51px;
    }
    .six-month-list .electro {
        margin-bottom: 28px;
    }
}

@media all and (min-width: 1150px) {
    .six-month-list .plan-data {
        margin-bottom: 25px;
    }
    .six-month-list .check-banks {
        margin-bottom: 67px;
    }
    .six-month-list .get-hep {
        margin-bottom: 50px;
    }
    .six-month-list .talk-banks {
        margin-bottom: 65px;
    }
}

@media all and (min-width: 1280px) {
    .six-month-list .plan-data {
        margin-bottom: 26px;
    }
    .six-month-list .talk-banks {
        margin-bottom: 52px;
    }
    .six-month-list .start-getting {
        margin-bottom: 15px;
    }
}

@media all and (min-width: 1380px) {
    
    .six-month-list .get-hep {
        margin-bottom: 28px;
    }
    .six-month-list .check-banks {
        margin-bottom: 55px;
    }
    .six-month-list .jacket {
        margin-bottom: 35px;
    }
}

@media all and (min-width: 1700px) {
    .six-month-list .get-hep {
        margin-bottom: 24px;
    }
    .six-month-list .talk-banks {
        margin-bottom: 24px;
    }
    .six-month-list .check-banks {
        margin-bottom: 40px;
    }

    .six-month-list.third-list .input-label-parent {
        margin-bottom: 24px;
    }
    .six-month-list.third-list .wear-sneakers {
        margin-bottom: 24px;
    }
}

@media all and (max-width: 405px) {
    .button-parent {
        flex-direction: column;
        bottom: -133px;
    }
    .button-parent button {
        margin: 0 auto 10px auto;
        width: 180px;
    }
    .button-parent button:last-child {
        margin-bottom: 0;
    }
    .blog-details + footer .line-divider {
        margin-top: 138px;
    }
}

@media only screen and (min-width: 186px) and (max-width: 191px) {
    .blog-details {
        margin-top: 125px;
    }
}

@media all and (max-width: 185px) {
    .search-wrap {
        top: 89px;
    }
    .blog-details {
        margin-top: 157px;
    }
}
@media all and (min-width: 2400px) {
    .blog-details .post-title {
        width: 62%;
        margin: 0 auto;
    }
}
@media only screen and (min-width: 960px) and (max-width: 1951px) {
    .card-container article:nth-child(n + 7):nth-child(-n + 12) {
        height: 306px;
    }
}
