.newsfeed {
  margin-bottom: 45px;
  margin-top: 142px;
}
.newsfeed h1 {
  font-size: 1.3125rem;
}
.news-article-container {
  width: 95%;
  margin: 0 auto;
  height: 100vh;
  overflow-y: scroll;
  border-radius: 18px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  scrollbar-color:#1493f3 darkgray;
  scrollbar-width: auto;
}
.news-hero {
  width: 100%;
  border-radius: 0;
  padding-top: calc(618 / 2543 * 100%);
  position: relative;
}
.news-hero figcaption {
  padding-left: 5px;
  font-size: .75rem;
}
.news-hero img {
  display: block;
  max-width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.feed-container {
  padding: 20px 10px 20px 10px;
  border-radius: 18px;
}
.feed-container h2 {
  margin-bottom: 4px;
}
.news-article-container a {
  display: block;
  padding: 5px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px auto;
  transition: 0.6s;
  position: relative;
}
.feed-container a::after{
  content: " ";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #1493f3;
  transform: scaleX(0);
  transform-origin: bottom left;
  transition: transform 0.6s;
}
.feed-container a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.article-date {
  font-weight: 700;
}
.news-article-container::-webkit-scrollbar {
  width: 10px;
}
.news-article-container::-webkit-scrollbar-track {
  background-color: darkgrey;
}
.news-article-container::-webkit-scrollbar-thumb {
  background-color: #1493f3;
}
/* Desktop menu */
@media all and (min-width: 960px) {
  .news-article-container {
      width: 70%;
 }
  .feed-container {
      padding: 20px;
 }
  .newsfeed {
      margin-bottom: 65px;
      margin-top: 92px;
 }
}
/* Wider screens */
@media all and (min-width: 1450px) {
  .news-article-container {
      width: 44%;
 }
}
