.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-evenly;
  background-color: #fff;
}

.footer .line-divider {
  margin-top: 0;
  flex-wrap: nowrap;
}

.footer .item a:after {
  content: " ";
  position: absolute;
  width: 98%;
  height: 3px;
  bottom: 2px;
  left: 0;
  background-color: #1493f3;
  transform: scaleX(0);
  transform-origin: bottom left;
  transition: transform 0.3s;
}

.footer .menu {
  flex-direction: column;
  margin-bottom: 15px;
  width: auto;
  border-bottom: 1px solid #a1a1a1;
}

.footer li a {
  padding: 13px 5px;
}

.footer .item a:hover:after {
  transform-origin: bottom left;
  transform: scaleX(1);
}

.footer .item a.disabled:hover:after {
  transform-origin: bottom left;
  transform: scaleX(0);
}

.footer .item {
  display: block;
  position: relative;
  width: auto;
  top: -6px;
}

.icons {
  padding: 25px 0 25px 0;
}

.icons a {
  text-decoration: none;
  font-size: 2rem;
  margin: 0.5rem;
  color: #000;
}

.credits, .copyright, .no-reproduction {
  font-size: .875rem;
  line-height: 1.6;
}

.credits {
  margin-bottom: 30px;
}

.form {
  padding: 0 0 35px 0;
  display: flex;
  justify-content: center;
  background-color: #000;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.optin-button, .form input, .contact-button {
  padding: 15px;
  width: 225px;
  font-size: 1rem;
  border-radius: 10px;
}

.form input {
  margin-bottom: 10px;
  background: none;
  border: none;
  border: 3px solid #fff;
  font-size: 1rem;
  outline: none;
  color: #fff;
}

.form input::placeholder {
  color: #fff;
  font-size: 1rem;
  letter-spacing: .03rem;
}

.form input:focus {
  border: 3px solid #1493f3;
}

.optin-button {
  background-color: #a91319;
  border: 3px solid #a91319;
  color: #fff;
  cursor: default;
}

.optin-button:focus {
  outline: 3px solid #1493f3;
}

.cta {
  background: #000;
  color: #fff;
  padding: 35px 5px 25px 5px;
  line-height: 1.6;
}

.cta p {
  letter-spacing: .03rem;
  font-size: 1.2rem;
  font-weight: 500;
  width: 250px;
  margin: auto;
}

.alert-comment {
  color: #df0d14;
  position: absolute;
  top: 120px;
  font-size: .825rem;
  outline: 2px solid #df0d14;
  padding: 3px;
  font-weight: 500;
}

#alert-comment, #alert-comment-2 {
  visibility: hidden;
}

#send-form, #send-form-2 {
  opacity: .4;
  cursor: default;
}

#send-form.activated, #send-form-2.activated {
  opacity: 1;
  cursor: pointer;
}

/* Desktop menu */
@media all and (min-width: 960px) {

  .footer .menu {
      flex-direction: row;
      margin-bottom: -1px;
      border-bottom: none;
  }

  .footer .item {
    padding-top: 0;
    padding-bottom: 0;
  }

  .form {
      flex-direction: row;
      position: relative;
 }

  .form input {
      margin-right: 10px;
      margin-bottom: 0;
 }

  .alert-comment {
      top: 62px;
 }

  .cta p {
      width: auto;
 }

 .footer .item:after {
  width: 75%;
  left: 10px;
 }

}

@media all and (max-width: 268px) {

  .optin-button, .form input {
      width: 200px;
 }

}

@media all and (max-width: 250px) {

  .cta h3 {
      font-size: 1rem;
      width: 150px;
 }

}