.top-to-bottom {
    position: relative;
  }

  .icon-position {
    position: fixed;
    bottom: 40px;
    right: 5px;
    z-index: 20;
  }

  .icon-style {
    background-color: #1493f3;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    color: #fff;
    cursor: pointer;
    /* animation: movebtn 3s ease-in-out infinite; */
    transition: all .5s ease-in-out;
  }

  .icon-style:hover {
    animation: none;
    background: #fff;
    color: #1493f3;
    border: 2px solid #1493f3;
  }

  .top-to-bottom:focus::after {
    content: "";
    position: fixed;
    bottom: 40px;
    right: 25px;

    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
    height: 50px;
    width: 50px;
  }

  @keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(20px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-20px);
    }
    100%{
      transform: translateY(0px);
    }
  }

  @media all and (max-width: 960px) {
    .top-to-bottom:focus::after {
      bottom: 38px;
      right: 1px;
      height: 36px;
      width: 39px;
    }
  }
  

  /* Desktop menu */
@media all and (min-width: 960px) {
  .icon-style {
    height: 50px;
    width: 50px;
  }

  .icon-position {
    right: 25px;
  }
}